* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family:
    YekanBakh,
    IRANSans,
    tahoma,
    Cabin,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9fafc;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/eot/IRANSansWeb.eot');
  src: url('assets/fonts/eot/IRANSansWeb.eot?#iefix') format('embedded-opentype'), url('assets/fonts/woff2/IRANSansWeb.woff2') format('woff2'), url('assets/fonts/woff/IRANSansWeb.woff') format('woff'), url('assets/fonts/ttf/IRANSansWeb.ttf') format('truetype')
}

@font-face {
  font-family: IRANSansBold;
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/eot/IRANSansWeb_Bold.eot');
  src: url('assets/fonts/eot/IRANSansWeb_Bold.eot') format('embedded-opentype'), url('assets/fonts/woff2/IRANSansWeb_Bold.woff2') format('woff2'), url('assets/fonts/woff/IRANSansWeb_Bold.woff') format('woff'), url('assets/fonts/ttf/IRANSansWeb_Bold.ttf') format('truetype')
}

@font-face {
  font-family: YekanBakh;
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/woff/yekan bakh en 04 regular.woff') format('woff'), url('assets/fonts/woff2/yekan bakh en 04 regular.woff2') format('woff2'), url('assets/fonts/ttf/yekan bakh en 04 regular.ttf') format('truetype')
}